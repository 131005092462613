<template>
    <div :style="styleObject" class="page-header align-items-start min-vh-screen my-transacions">
        <span class="mask bg-white"></span>
        <div class="container">
            <div class="row">
            <div class="col-12" style="text-align: center;">
                <i class="material-icons-round opacity-10 fs-5" style="font-size: 10rem !important;color: green;">check_circle</i>
                <div
                class="color-common pb-2 pt-3 font-weight-bolder h4"
                :style="`color: black !important;text-align: center`"
                >
                Congratulations
                </div>
                <div
                class="color-common pb-2 pt-3 font-weight-bolder h4"
                style="color: gray !important;text-align: center;font-size:14px;"
                >
                Your membership has been activated successfully.
                </div>
            </div>
            </div>
            <div class="row" style="justify-content: center;margin-top: 10px;">
            <material-button
                @click="closePage"
                class="mb-1 col-lg-2 col-md-3 col-4 bg-color-common"
                :style="`background: green !important; color: white !important;`"
            >
                <div class="text-center">Close</div>
            </material-button>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { mapMutations, mapActions } from "vuex";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialButton from "@/components/MaterialButton.vue";
  
  export default {
    name: "my-transacions",
    components: {
        MaterialButton
    },
    data() {
      return {
        merchantCode: ""
      };
    },
    mounted() {
        const code = localStorage.getItem("MERCHANT_CODE", "");
        this.merchantCode = code ? `/${code}` : "";
    },
    computed: {
      styleObject() {
          return {
              '--label-color': this.$store.state.labelColor,
          };
      },
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
        ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),
        closePage() {
            // window.location.href = location.protocol + '//' + location.host+"/minty/profile"
            window.location.href = this.merchantCode+'/home'
        },
    },
  };
  </script>
  <style>
  .item-content h4 {
    font-size: 16px;
    color: #8f8e8e;
    font-weight: 500;
  }
  
  .item-content em {
    font-size: 14px;
    color: #222;
  }
  
  .avatar-invite {
    width: 80px;
    height: 90px;
  }
  #invite-friends p {
    font-size: 13px;
  }
  </style>