import api from './api';

class RewardService {
  getList(params) {
    return api.post('/member/rewards', params);
  }

  getRewardDetail(reward_id, is_myreward = 0, campaign_id = 0) {
    return api.get('/member/rewards/detail/' + reward_id + '/' + is_myreward + '/' + campaign_id);
  }

  processExchange(reward_id) {
    return api.post('/member/rewards/exchange', { reward_id: reward_id });
  }

  myRewards(type, search_keyword, params) {
    params.type = type
    params.search_keyword = search_keyword
    return api.post('/member/rewards/my-reward', params);
  }

  getBranches() {
    return api.post('/member/branches');
  }

  getListVoucherBundle(params) {
    return api.post('/member/vouchers-bundle', params);
  }

  getVoucherBundleDetail(params) {
    return api.post('/member/voucher-bundle/detail', params);
  }

  processExchangeVoucherBundle(reward_id) {
    return api.post('/member/voucher-bundle/exchange', { reward_id: reward_id });
  }

  processExchangeVoucherBundleByCard(reward_id, session_id) {
    return api.post('/member/voucher-bundle/exchange-by-card', { reward_id: reward_id, session_id: session_id });
  }
}

export default new RewardService();
