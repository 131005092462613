<template>
  <div
    class="page-header align-items-start min-vh-100 topup-list"
    :style="styleObject"
  >
    <span class="mask bg-white"></span>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-8 col-12 mx-auto">
          <div class="card-body ps-0 pe-0 pb-90" v-if="!loaderActive">
            <div
              class="pb-0 font-weight-bolder h4"
              :style="`color: ${this.$store.state.labelColor} !important`"
            >
              TOP UP
            </div>
            <div class="row mb-2">
              <div class="col-8">
                Wallet Balance
                <span
                  :style="`color: ${this.$store.state.labelColor} !important`"
                >
                  S$
                  {{
                    new Intl.NumberFormat("en", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(cashbackAmount)
                  }}
                </span>
              </div>
              <div
                class="col-4 d-flex justify-content-end align-items-center"
                @click="gotoTransaction()"
              >
                <img
                  class="icon-history"
                  src="../assets/img/method/history.png"
                />
                <span class="ps-2 link-style text-size-14">History</span>
              </div>
            </div>
            <ul class="topup-list-price ps-0 ms-0">
              <li
                v-for="(price, index) in prices"
                :index="index"
                :key="index"
                class="mb-3"
                @click="selectPrice(index, price)"
              >
                <div class="d-flex align-items-center">
                  <input
                    type="radio"
                    class="radio-custom"
                    name="btnradio"
                    :id="`btnradio${index}`"
                    autocomplete="off"
                  />
                  <label
                    class="btn btn-price mb-0 ms-3"
                    :for="`btnradio${index}`"
                  >
                    <strong class="text-size-20">
                      + S$
                      {{
                        new Intl.NumberFormat("en", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }).format(price.topup_amount)
                      }}</strong
                    >
                    <br />
                    <span
                      class="text-size-11 d-flex justify-content-center"
                      style="text-transform: capitalize; color: #495057"
                    >
                      Payment Amount S${{
                        new Intl.NumberFormat("en", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }).format(price.payment_amount)
                      }}</span
                    >
                  </label>
                </div>
                <div
                  :class="`sub_price_item_${index} sub_price_item sub-amount-info ps-5 mt-2 d-none`"
                >
                  <div class="row">
                    <div class="col-6 text-size-14">Amount Charged</div>
                    <div class="col-6">
                      S${{
                        new Intl.NumberFormat("en", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }).format(price.payment_amount)
                      }}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="row mb-2">
              <div class="color-common font-weight-bolder" :style="`color: ${this.$store.state.labelColor} !important`">
                Terms & Conditions
              </div>
              <div v-html="terms_conditions" class="font-content text-dark c-terms-conditions"></div>
            </div>
            <!-- <div v-for="(value, index) in rewardDetail.terms_arr" :item="value" :index="index" :key="index"
              class="row mb-2">
              <div class="d-flex bd-highlight" v-if="value">
                <i class="fas fa-check pe-2 pt-1"></i>
                <p class="font-content text-dark ">{{ value }}</p>
              </div>
            </div> -->
            <!-- <h5 class="mt-3">All top-up methods</h5>
            <ul class="ms-0 ps-0 list-method">
              <li class="mb-3" @click="selectMethod('card')">
                <div class="d-flex align-items-center">
                  <input
                    type="radio"
                    class="radio-custom"
                    name="method"
                    id="method1"
                    autocomplete="off"
                  />
                  <label
                    class="btn-method mb-0 ms-3 d-flex align-items-center"
                    for="method1"
                  >
                    <img
                      class="icon-method"
                      src="../assets/img/method/card.png"
                    />
                    <span class="ms-2">Cards</span>
                  </label>
                </div>
              </li>
              <li class="mb-3" @click="selectMethod('apple')">
                <div class="d-flex align-items-center">
                  <input
                    type="radio"
                    class="radio-custom"
                    name="method"
                    id="method2"
                    autocomplete="off"
                  />
                  <label
                    class="btn-method mb-0 ms-3 d-flex align-items-center"
                    for="method2"
                  >
                    <img
                      class="icon-method"
                      src="../assets/img/method/apple.png"
                    />
                    <span class="ms-2">Pay</span>
                  </label>
                </div>
              </li>
              <li @click="selectMethod('google')">
                <div class="d-flex align-items-center">
                  <input
                    type="radio"
                    class="radio-custom"
                    name="method"
                    id="method3"
                    autocomplete="off"
                  />
                  <label
                    class="btn-method mb-0 ms-3 d-flex align-items-center"
                    for="method3"
                  >
                    <img
                      class="icon-method"
                      src="../assets/img/method/google.png"
                    />
                    <span class="ms-2">Google Pay</span>
                  </label>
                </div>
              </li>
            </ul> -->
          </div>
        </div>
      </div>
      <div class="footer-topup mx-auto">
        <button
          :disabled="!currentPrice || !currentMethod"
          :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
          type="button"
          class="
            btn
            bg-color-common
            btn-success
            col-lg-3 col-md-3 col-4
            mx-auto
          "
          @click="processPayment"
          data-bs-dismiss="modal"
        >
          Pay
        </button>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <TopupCompleted :processDone="processDone" :amount="currentPrice" />
    <material-loading :active="loaderActive" />
  </div>
</template>

<script>
import MaterialLoading from "@/components/MaterialLoading.vue";
import { mapMutations, mapActions } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import TopupCompleted from "@/components/TopupCompleted.vue";
import MemberService from "../services/member.service";
import StripeService from "../services/stripe.service";
import "bootstrap/dist/js/bootstrap.bundle";
import $ from "jquery";
export default {
  name: "my-transacions",
  components: {
    MaterialSnackbar,
    TopupCompleted,
    MaterialLoading,
  },
  data() {
    return {
      prices: [],
      terms_conditions: '',
      currentPrice: 0,
      currentMethod: "card",
      merchantCode: "",
      memberInfo: {},
      cashbackAmount: 0,
      loaderActive: true,
      snackbar: "",
      message: ""
    };
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    const isCheckMerchantConnectedStripe = localStorage.getItem("IS_CHECK_MERCHANT_CONNECTED_STRIPE", 'false');
    this.merchantCode = code ? `/${code}` : "";
    if(isCheckMerchantConnectedStripe === 'false') {
      window.location.href = this.merchantCode+'/home'
    }
    //load after payment done
    var session_id = this.$route.query.session_id;
    var price = this.$route.query.price;
    var id = this.$route.query.id;
    if(session_id) { // call back after pay success - stripe
      this.loaderActive = true;
      StripeService.addDirectAmountAfterPayWithCard(session_id, price, id, code).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.currentPrice = price;
            this.loadDonePayment();
            // this.snackbar = "success";
            // this.message = "Successfully!";
            // this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.loaderActive = false;
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.loaderActive = false;
        }
      );
    }
    this.getMemberInfo();
    this.getTopupList();
  },
  computed: {
    styleObject() {
      return {
        "--button-color": this.$store.state.buttonColor,
      };
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),
    selectPrice(index, value) {
      this.currentPrice = value;
      $(".sub_price_item").removeClass("active");
      $(".sub_price_item_" + index).addClass("active");
    },
    selectMethod(method) {
      this.currentMethod = method;
    },
    processDone() {
      $(".btn-topup_completed_modal").click();
      const merchantCode = localStorage.getItem('MERCHANT_CODE', '');
      var url = location.protocol + '//' + location.host+"/"+merchantCode+"/top-up";
      window.location.href = url
    },
    processPayment() {
      // click refdirect to srtipe payment
      // $(".btn-topup_completed_modal").click();
      this.loaderActive = true;
      const merchantCode = localStorage.getItem('MERCHANT_CODE', '');
      var url = location.protocol + '//' + location.host+"/"+merchantCode+"/top-up";
      var payment_amount = new Intl.NumberFormat("en", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(this.currentPrice.payment_amount)
      var dataSend = {
          success_url: url+"?session_id={CHECKOUT_SESSION_ID}&price="+this.currentPrice.payment_amount+"&id="+this.currentPrice.id,
          cancel_url: url,
          images: "",
          name: "TOP UP "+payment_amount,
          unit_amount: this.currentPrice.payment_amount,
          quantity: 1
      };
      StripeService.checkoutSessionStripe(dataSend, merchantCode).then(
        (response) => {
          if (response.data.result.isSuccess) {
              window.location.href = response.data.data.url
          } else {
            this.loaderActive = false;
          }
        },
        (error) => {
          console.log(error);
          this.loaderActive = false;
        }
      );
    },
    loadDonePayment() {
      $(".btn-topup_completed_modal").click();
    },
    gotoTransaction() {
      this.$router.push(this.merchantCode + "/my-transactions");
    },
    getMemberInfo() {
      MemberService.getProfile().then(
        (response) => {
          this.memberInfo = response.data.data;
          this.cashbackAmount = this.memberInfo.amount_in_account ?? 0;
          this.loaderActive = false;
        },
        (error) => {
          console.log(error);
          this.loaderActive = false;
        }
      );
    },
    getTopupList() {
      MemberService.getTopupList().then(
        (response) => {
          this.prices = response.data.data.topup;
          this.terms_conditions = response.data.data.termsConditions;
          this.loaderActive = false;
        },
        (error) => {
          console.log(error);
          this.loaderActive = false;
        }
      );
    },
    closeSnackbar() {
      this.snackbar = null;
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>
<style scoped>
.link-style {
  color: blue;
}
.icon-history {
  width: 20px;
  height: 20px;
}
.footer-topup {
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  left: 0px;
}
.icon-method {
  width: 30px;
  height: 30px;
}
.btn-method {
  font-size: 15px;
}
.topup-list-price li,
.list-method li {
  list-style: none !important;
}
.topup-list-price li div.active {
  display: block !important;
}
.list-payment-method li {
  list-style: none;
  line-height: 40px;
  border-bottom: 1px solid #d6d6d6;
}
.btn-price {
  background: white;
  border-radius: 50px;
  min-width: 230px;
  border: 1px solid #000000 !important;
}
.sub-amount-info {
  max-width: 320px;
}
/* Radios */
.topup-list input[type="radio"] {
  width: 1.5em;
  height: 1.5em;
}
input[type="radio"]:checked:after {
  width: 1em;
  height: 1em;
  border-radius: 15px;
  position: relative;
  left: 4px;
  top: 2px;
  background-color: #495057;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid #495057;
}
</style>