<template>
  <div :style="styleObject" class="page-header align-items-start min-vh-screen my-transacions">
    <span class="mask bg-white"></span>
    <div class="container">
      <div class="row">
        <div class="notifications">
          <material-alert
            v-if="error"
            color="danger"
            icon="ni ni-like-2 ni-lg"
            dismissible
          >
            {{ error }}
          </material-alert>
        </div>
        <div class="col-12">
          <div
            class="color-common pb-2 pt-3 font-weight-bolder h4"
            :style="`color: ${this.$store.state.labelColor} !important`"
          >
            INVITE FRIENDS
          </div>
        </div>
        <div class="header-timeline">
          <em
            >Invite your friends and get rewarded with a discount voucher for every friend that sign up!</em
          >
        </div>
      </div>
      <div id="invite-friends" class="row">
        <div class="col-sm-12 col-md-6">
          <div class="row">
            <div class="col-4 card-text text-center p-3">
              <img
                class="py-2 avatar-invite"
                src="../assets/img/invitefriend/step1.png"
              />
              <h5 class="text-number form-title">1</h5>
              <p>Copy invite link & share to your friends</p>
            </div>
            <div class="col-4 card-text text-center p-3">
              <img
                class="py-2 avatar-invite"
                src="../assets/img/invitefriend/step2.png"
              />
              <h5 class="text-number form-title">2</h5>
              <p>Your friend sign up account on your link</p>
            </div>
            <div class="col-4 card-text text-center p-3">
              <img
                class="py-2 avatar-invite"
                src="../assets/img/invitefriend/step3.png"
              />
              <h5 class="text-number form-title">2</h5>
              <p>You get rewarded!</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="input-group input-group-static mb-3">
          <label
            for="form-control"
            class="ms-0"
            :style="`color: ${this.$store.state.labelColor} !important`"
            >Your invite link</label
          >
          <div class="form-control p-0 d-flex no-bg" id="form-control">
            <input
              type="text"
              class="form-control copytextarea"
              :value="inviteUrl"
              readonly
            />
            <material-button
              class="mb-1 col-lg-2 col-md-3 col-4 bg-color-common"
              @click="copyURL"
              :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
            >
              <div class="text-center">Copy</div>
            </material-button>
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>

<script>
import MaterialAlert from "@/components/MaterialAlert.vue";
import { mapMutations, mapActions } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MemberService from "../services/member.service";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  name: "my-transacions",
  components: {
    MaterialAlert,
    MaterialSnackbar,
    MaterialButton,
  },
  data() {
    return {
      inviteUrl: "",
      merchantCode: "",
      memberInfo: {},
      message: "Copied your code to clip board",
      snackbar: "",
    };
  },
  mounted() {
    this.getMemberInfo();
  },
  computed: {
    styleObject() {
        return {
            '--label-color': this.$store.state.labelColor,
        };
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),
    getMemberInfo() {
      MemberService.getProfile().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.memberInfo = response.data.data;
            const code = localStorage.getItem("MERCHANT_CODE", "");
            this.merchantCode = code ? `/${code}` : "";
            this.inviteUrl = `${
              window.location.origin + this.merchantCode
            }/sign-in/${this.memberInfo.my_referral_code}`;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    copyURL() {
      const ipnElement = document.querySelector(".copytextarea");
      ipnElement.select(); // step 4
      document.execCommand("copy"); // step 5
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>
<style>
.item-content h4 {
  font-size: 16px;
  color: #8f8e8e;
  font-weight: 500;
}

.item-content em {
  font-size: 14px;
  color: #222;
}

.avatar-invite {
  width: 80px;
  height: 90px;
}
#invite-friends p {
  font-size: 13px;
}
</style>