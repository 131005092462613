import api from './api';

class MerchantService {
  getListMerchant(search_keyword = '') {
    return api.get('/admin/merchant?search_keyword=' + search_keyword);
  }

  createMerchant(dataForm) {
    return api.post('/admin/merchant/add', dataForm, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    });
  }

  deleteMerchant(merchantId) {
    return api.post('/admin/merchant/delete/' + merchantId);
  }

  getBranches(search_keyword) {
    return api.post('/member/find-branch', { search_keyword: search_keyword });
  }

}

export default new MerchantService();
