<template>
  <div class="modal fade" id="bottom_modal_congratulation" tabindex="-1" role="dialog"
    aria-labelledby="bottom_modal_congratulation" style="z-index: 10000;" ref="bsmodal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content text-center">
        <div class="modal-header d-flex justify-content-end" style="border: 0; padding: 0rem 1rem;">
          <span aria-hidden="true" style="font-size: 25px; color: #222;" data-bs-dismiss="modal">&times;</span>
        </div>
        <div class="modal-body">
          <div class="row d-flex bd-highlight">
            <div class="text-dark d-flex justify-content-center pb-2 px-4">
                <h5 v-html="message"></h5>
            </div>
            <div>
              <div class="row wrap-card justify-content-center">
                <div class="col-lg-6 col-12 col-md-6 mb-2">
                  <img :src="voucherIconUrl" class="img-card" alt="user1"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-center py-3">
          <div class="d-flex justify-content-center">
            <h4>You earned a reward</h4>
          </div>
          <div class="d-flex justify-content-center" style="font-size: 14px;">
            <i class="material-icons opacity-10 w-0 incon-successfully">check_circle_outline_rounded_icon</i>
            <div class="ps-4">Profile completed successfully</div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn-show-modal-congratulation" style="display: none" data-bs-toggle="modal"
      data-bs-target="#bottom_modal_congratulation">Show Model</button>
  </div>
</template>

<script>
export default {
  name: "ExchangeStatus",
  props: {
    id: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: "",
    },
    voucherIconUrl: {
      type: String,
      default: "",
    },
    btnClose: {
      type: String,
      default: "",
    },
    btnGo: {
      type: String,
      default: "",
    },
    goPage: {
      type: Function,
      default: () => { },
    },
    memberInfo: {
      type: Object,
      required: false
    }
  },
  mounted() {
    const code = localStorage.getItem('MERCHANT_CODE', '');
    var merchantCode = code ? `/${code}` : ''
    this.$refs.bsmodal.addEventListener('hidden.bs.modal', event => { 
      console.log(event);
      // is not paid member ship & merchant connected to stripe && count_paid_membership_reward_by_merchant > 0
      const isCheckMerchantConnectedStripe = localStorage.getItem("IS_CHECK_MERCHANT_CONNECTED_STRIPE", 'false');
      var count_paid_membership_reward_by_merchant = this.memberInfo.count_paid_membership_reward_by_merchant;
      if(!this.memberInfo.is_paid_member_ship && isCheckMerchantConnectedStripe === 'true' && count_paid_membership_reward_by_merchant > 0) {
        this.$router.push(merchantCode + '/get-member-ship');
      }
    })
  }
};
</script>
<style scoped>
.bg-color-common {
  color: #fff;
}

/* Css model */
.modal.modal-left .modal-dialog,
.modal.modal-right .modal-dialog,
.modal.modal-top .modal-dialog,
.modal.modal-bottom .modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/* Left & Right */

.modal.modal-left .modal-dialog,
.modal.modal-right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 500px;
  max-width: 100%;
  height: 100%;
}

.modal.modal-left .modal-content,
.modal.modal-right .modal-content {
  height: 100%;
  overflow-y: none;
}

.modal.modal-left .modal-body,
.modal.modal-right .modal-body {
  padding: 15px 15px 80px;
}

.modal.modal-left.fade .modal-dialog {
  left: -500px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.modal-left.fade.show .modal-dialog {
  left: 0;
}

.modal.modal-right.fade .modal-dialog {
  right: -500px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.modal-right.fade.show .modal-dialog {
  right: 0;
}

.modal.modal-left .modal-content,
.modal.modal-right .modal-content,
.modal.modal-top .modal-content,
.modal.modal-bottom .modal-content,
.modal.modal-full .modal-content {
  border-radius: 0;
  border: none;
}

.modal.modal-left .modal-dialog.modal-sm,
.modal.modal-right .modal-dialog.modal-sm {
  width: 300px;
}

.modal.modal-left .modal-dialog.modal-lg,
.modal.modal-right .modal-dialog.modal-lg {
  width: 800px;
}

.modal.modal-left .modal-dialog.modal-xl,
.modal.modal-right .modal-dialog.modal-xl {
  width: 1140px;
}

/* Top and Bottom */

.modal.modal-top .modal-dialog,
.modal.modal-bottom .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.modal.modal-top .modal-content,
.modal.modal-bottom .modal-content {
  height: auto;
  overflow-y: none;
}

.modal.modal-top .modal-body,
.modal.modal-bottom .modal-body {
  padding: 15px 15px;
}

/* Top */

.modal.modal-top.fade .modal-dialog {
  top: -100%;
  -webkit-transition: opacity 0.3s linear, top 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, top 0.3s ease-out;
  -o-transition: opacity 0.3s linear, top 0.3s ease-out;
  transition: opacity 0.3s linear, top 0.3s ease-out;
}

.modal.modal-top.fade.show .modal-dialog {
  top: 0;
}

/* Bottom */

.modal.modal-bottom.fade .modal-dialog {
  bottom: -100%;
  -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  transition: opacity 0.3s linear, bottom 0.3s ease-out;
}

.modal.modal-bottom.fade.show .modal-dialog {
  bottom: 0;
}

.modal.modal-bottom.fade .modal-dialog {
  bottom: -100%;
}

/* Full Screen */

.modal.modal-full .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.modal.modal-full .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.modal-full .close-modal {
  position: fixed;
  top: 0;
  right: 3rem;
}

/* Footer */

.modal-footer-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-radius: 0;
}

/* XS */

.modal.modal-left.xs .modal-body,
.modal.modal-right.xs .modal-body {
  padding: 15px;
}

.btn-close {
  font-size: 25px;
  color: #222 !important;
}

.btn-close span {
  color: #222;
}

.incon-successfully{
  color: rgb(25, 118, 210); 
  margin-top: 1px;
}
</style>
