<template>
  <div class="form-check p-0" :style="styleObject">
    <input
      :style="inputStyle"
      :id="id"
      class="form-check-input"
      type="checkbox"
      :name="name"
      :value="modelValue"
      :checked="checked"
      @input="$emit('update:modelValue', $event.target.checked)"
    />
    <label :for="id" class="custom-control-label">
      <slot name="label" />
    </label>
  </div>
</template>

<script>
export default {
  name: "MaterialCheckbox",
  props: {
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    },
    inputStyle: {
      type: Object
    }
  },
  computed: {
    styleObject() {
      return {
        "--label-color": this.$store.state.labelColor,
      };
    },
  },
};
</script>
<style scoped>
.form-check:not(.form-switch) .form-check-input[type=checkbox]:checked {
    background: var(--label-color);
}

.form-check:not(.form-switch) .form-check-input[type=checkbox]:checked, .form-check:not(.form-switch) .form-check-input[type=radio]:checked {
    border-color: var(--label-color);
}
</style>
