import api from './api';

class CampaignService {
  getList() {
    return api.post('/member/campaigns');
  }

  processExchange(data) {
    return api.post('/member/campaigns/reward/exchange', data);
  }

  discountDetail(data) {
    return api.post('/member/campaigns/discount/detail', data);
  }
}

export default new CampaignService();
