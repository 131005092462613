<template>
  <div :style="styleObject" class="mb-3">
    <input class="nosubmit" type="search" id="keyword_search" extraClass name="keyword_search" :placeholder="placeholder"
      @input="onChangesearchKeyword">
  </div>
</template>

<script>
export default {
  name: "SearchInput",
  props: {
    onChangesearchKeyword: {
      type: Function,
      default: () => { },
    },
    placeholder: {
      type: String,
      default: "Enter text to search...",
    },
  },
  computed: {
    styleObject() {
        return {
            '--label-color': this.$store.state.labelColor,
        };
    },
  },
};
</script>
<style>
input[type="search"] {
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
}

input[type="search"]::placeholder {
    color: #bbb;
    text-align: left;
    font-weight: normal;
}

input[type="search"]:focus {
    border-color: var(--label-color) !important;
    outline: none;
    border: none;
    border-bottom: 2px solid var(--label-color) !important;
}

.nosubmit-parent {
    border: none;
    padding: 0;
}

input.nosubmit {
    border-bottom: 2px solid #d2d2d2;
    width: 100%;
    padding: 9px 4px 9px 40px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}
</style>