<template>
    <div class="modal modal-bottom fade" id="qrcode-discount-modal" tabindex="-1" role="dialog" aria-labelledby="qrcode-discount-modal"
      style="z-index: 10000;">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header" style="border: 0; padding: 0.1rem 1rem;">
            <!-- <h5 class="modal-title">Bottom Modal Title</h5> -->
            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 col-md-8 col-12 mx-auto">
                <div class="row mb-3">
                  <div class="color-common font-weight-bolder reward-title"
                    :style="`color: ${this.$store.state.labelColor} !important`">
                    <em>{{ discountDetail.rule_name }}</em>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="color-common reward-title" :style="`color: ${this.$store.state.labelColor} !important`">
                    Expiry: {{ discountDetail.expiry_date_text }}
                  </div>
                </div>
                <div class="row mb-4 wrap-qrcode text-center" v-if="isShowQr">
                  <VueQRCodeComponent :text="discountDetail.rule_code" size="200" color="#222222" bg-color="#ffffff" class="d-flex justify-content-center"
                    error-level="L" />
                </div>
                <div class="mb-2 text-center wrap-code d-flex">
                  <input type="text" class="form-control js-copytextarea discount-code" :value="discountDetail.rule_code" readonly>
                  <i class="material-icons-round opacity-10 fs-5" @click="coppyDiscountCode()">copy</i>
                </div>
                <div class="row mb-3">
                  <vue-barcode :value="discountDetail.rule_code" :options="{ displayValue: true }"></vue-barcode>
                </div>
                <div class="row mb-3 mx-1" v-if="discountDetail.branch_can_exchange_text">
                  <div class="d-flex bd-highlight">
                    <i class="fas fa-check pe-2 pt-1" style="color: #049E0E;"></i>
                    <p class="font-content text-dark">{{ discountDetail.branch_can_exchange_text }}</p>
                  </div>
                </div>
                <div class="row mb-3 mx-1" v-if="discountDetail.item_apply_text">
                  <div class="d-flex bd-highlight">
                    <i class="fas fa-check pe-2 pt-1" style="color: #049E0E;"></i>
                    <p class="font-content text-dark">{{ discountDetail.item_apply_text }}</p>
                  </div>
                </div>
                <div class="row mb-3 mx-1" v-if="discountDetail.order_minimum_text">
                  <div class="d-flex bd-highlight">
                    <i class="fas fa-check pe-2 pt-1" style="color: #049E0E;"></i>
                    <p class="font-content text-dark">{{ discountDetail.order_minimum_text }}</p>
                  </div>
                </div>
                <hr class="row" v-if="discountDetail.terms_conditions != '' && (discountDetail.branch_can_exchange_text || discountDetail.item_apply_text || discountDetail.order_minimum_text)">
                <div class="row mb-3 mx-1" v-if="discountDetail.terms_conditions != ''">
                  <div class="color-common font-weight-bolder"
                    :style="`color: ${this.$store.state.labelColor} !important`">
                    Terms & Conditions
                  </div>
                </div>
                <div v-for="(value, index) in discountDetail.terms_arr" :item="value" :index="index" :key="index"
                  class="row mb-3 mx-1">
                  <div class="d-flex bd-highlight" v-if="value">
                    <i class="fas fa-check pe-2 pt-1"></i>
                    <p class="font-content text-dark c-terms-conditions">{{ value }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="btn-qrcode-discount-modal" style="display: none" data-bs-toggle="modal"
        data-bs-target="#qrcode-discount-modal">Success</button>
    </div>
  </template>
  
  <script>
  import VueQRCodeComponent from 'vue-qrcode-component'
  export default {
    name: "QrcodeReward",
    components: {
      VueQRCodeComponent
    },
    props: {
      discountDetail: {
        type: Object,
        default() {
          return {
          }
        }
      },
      isShowQr: {
        type: Boolean,
        default() {
          return true
        }
      },
    },
    methods: {
      coppyDiscountCode() {
        const ipnElement = document.querySelector('.discount-code')
        ipnElement.select()              // step 4
        document.execCommand('copy')     // step 5
      }
    }
  };
  </script>
  <style scoped>
  .bg-color-common {
    color: #fff;
  }
  
  #qrcode-discount-modal .modal-dialog {
    height: 70vh;
  }
  
  #qrcode-discount-modal .modal-body {
    overflow-y: scroll;
    height: 68vh;
  }
  
  .wrap-qrcode {
    width: 200px;
    margin: 0px auto;
  }
  
  #qrcode-discount-modal p {
    margin: 0px;
  }
  
  /* Css model */
  .modal.modal-left .modal-dialog,
  .modal.modal-right .modal-dialog,
  .modal.modal-top .modal-dialog,
  .modal.modal-bottom .modal-dialog {
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }
  
  /* Left & Right */
  
  .modal.modal-left .modal-dialog,
  .modal.modal-right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 500px;
    max-width: 100%;
    height: 100%;
  }
  
  .modal.modal-left .modal-content,
  .modal.modal-right .modal-content {
    height: 100%;
    overflow-y: auto;
  }
  
  .modal.modal-left .modal-body,
  .modal.modal-right .modal-body {
    padding: 15px 15px 80px;
  }
  
  .modal.modal-left.fade .modal-dialog {
    left: -500px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
  }
  
  .modal.modal-left.fade.show .modal-dialog {
    left: 0;
  }
  
  .modal.modal-right.fade .modal-dialog {
    right: -500px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }
  
  .modal.modal-right.fade.show .modal-dialog {
    right: 0;
  }
  
  .modal.modal-left .modal-content,
  .modal.modal-right .modal-content,
  .modal.modal-top .modal-content,
  .modal.modal-bottom .modal-content,
  .modal.modal-full .modal-content {
    border-radius: 0;
    border: none;
  }
  
  .modal.modal-left .modal-dialog.modal-sm,
  .modal.modal-right .modal-dialog.modal-sm {
    width: 300px;
  }
  
  .modal.modal-left .modal-dialog.modal-lg,
  .modal.modal-right .modal-dialog.modal-lg {
    width: 800px;
  }
  
  .modal.modal-left .modal-dialog.modal-xl,
  .modal.modal-right .modal-dialog.modal-xl {
    width: 1140px;
  }
  
  /* Top and Bottom */
  
  .modal.modal-top .modal-dialog,
  .modal.modal-bottom .modal-dialog {
    position: fixed;
    margin: auto;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  
  .modal.modal-top .modal-content,
  .modal.modal-bottom .modal-content {
    height: auto;
    overflow-y: auto;
  }
  
  .modal.modal-top .modal-body,
  .modal.modal-bottom .modal-body {
    padding: 15px 15px;
  }
  
  /* Top */
  
  .modal.modal-top.fade .modal-dialog {
    top: -100%;
    -webkit-transition: opacity 0.3s linear, top 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, top 0.3s ease-out;
    -o-transition: opacity 0.3s linear, top 0.3s ease-out;
    transition: opacity 0.3s linear, top 0.3s ease-out;
  }
  
  .modal.modal-top.fade.show .modal-dialog {
    top: 0;
  }
  
  /* Bottom */
  
  .modal.modal-bottom.fade .modal-dialog {
    bottom: -100%;
    -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
    -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
    transition: opacity 0.3s linear, bottom 0.3s ease-out;
  }
  
  .modal.modal-bottom.fade.show .modal-dialog {
    bottom: 0;
  }
  
  .modal.modal-bottom.fade .modal-dialog {
    bottom: -100%;
  }
  
  /* Full Screen */
  
  .modal.modal-full .modal-dialog {
    position: fixed;
    margin: auto;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
  
  .modal.modal-full .modal-content {
    height: 100%;
    overflow-y: auto;
  }
  
  .modal.modal-full .close-modal {
    position: fixed;
    top: 0;
    right: 3rem;
  }
  
  /* Footer */
  
  .modal-footer-fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    border-radius: 0;
  }
  
  /* XS */
  
  .modal.modal-left.xs .modal-body,
  .modal.modal-right.xs .modal-body {
    padding: 15px;
  }
  
  .btn-close {
    font-size: 25px;
    color: #222 !important;
  }
  
  .btn-close span {
    color: #222;
  }
  
  .wrap-code {
    border: 1px solid rgb(228, 228, 228);
    position: relative;
    width: 200px;
    margin: 0px auto;
    left: 0px;
  }
  
  .wrap-code i {
    position: absolute;
    right: 5px;
    top: 5px;
  }
  
  </style>
  