<template>
  <aside id="sidenav-main" class="sidenav navbar navbar-vertical navbar-expand-xs border-0"  :style="`background-image: linear-gradient(195deg, ${this.$store.state.backgroundColor} 0%, ${this.$store.state.backgroundColor} 100%) !important`" :class="`${
      isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start'
    } ${sidebarType}`">
    <div class="sidenav-header d-flex px-0 py-2" style="height: 56px; position: relative;">
      <i class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true" id="iconSidenav"></i>
      <div class="row col-12 d-flex">
        <div class="col-9 d-flex d-flex justify-content-center">
          <img v-if="this.$store.state.merchantLogo" :src="this.$store.state.merchantLogo" class="avatar-md border-radius-lg" alt="user1" style="height: 50px;"/>
          <img v-else src="../../assets/img/logo.png" class="avatar-md border-radius-lg" alt="user1" style="height: 50px;"/>
        </div>
        <div class="col-1">
          <i class="fa fa-times icon-close-sidenav-main" style="position: absolute; right: 12px;" aria-hidden="true" @click="toggleSidebar()"></i>
        </div>
      </div>
    </div>
    <hr class="horizontal light mt-0 mb-2" />
    <sidenav-list />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo-ct.png";
import logoDark from "@/assets/img/logo-ct-dark.png";
import { mapMutations, mapState } from "vuex";

export default {
  name: "index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoDark,
    };
  },
  computed: {
    ...mapState(["isRTL", "sidebarType", "isDarkMode"]),
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    toggleSidebar() {
      this.navbarMinimize();
    }
  }
};
</script>
