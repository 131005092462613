<template>
  <div :style="styleObject" class="page-header align-items-start min-vh-100">
    <span class="mask bg-white"></span>

    <div class="container">
      <div class="row">
        <div class="notifications">
          <material-alert
            v-if="error"
            color="danger"
            icon="ni ni-like-2 ni-lg"
            dismissible
          >
            {{ error }}
          </material-alert>
        </div>
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card-body" v-if="isValidLink">
            <div v-if="isNewPassword" class="mb-2 mt-5">
              <div
                class="text-center text-green fw-500"
                :style="`color: ${this.$store.state.labelColor} !important'`"
              >
                <span>Create new passcode</span>
              </div>
              <div
                id="otp"
                class="inputs d-flex flex-row justify-content-center mt-10"
              >
                <v-otp-input
                  ref="inputNewPassword"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="4"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  :conditionalClass="['one', 'two', 'three', 'four']"
                  :placeholder="['', '', '', '']"
                  @on-change="handleOnChange"
                  @on-complete="handleOnCompleteNewPassword"
                  v-model="newPassword"
                />
              </div>
            </div>
            <div v-if="isNewPasswordAgain" class="mb-2 mt-5">
              <div
                class="text-center text-green fw-500"
                :style="`color: ${this.$store.state.labelColor} !important'`"
              >
                <span>Confirm new passcode</span>
              </div>
              <div
                id="otp"
                class="inputs d-flex flex-row justify-content-center mt-10"
              >
                <v-otp-input
                  ref="inputNewPasswordAgain"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="4"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  :conditionalClass="['one', 'two', 'three', 'four']"
                  :placeholder="['', '', '', '']"
                  @on-change="handleOnChange"
                  @on-complete="handleOnCompleteNewPasswordAgain"
                  v-model="newPasswordAgain"
                />
              </div>
            </div>
            <div v-if="isUpdateSuccess" class="mb-2 mt-5">
              <div
                id="otp"
                class="inputs d-flex flex-row justify-content-center mt-10"
              >
                <img src="../assets/img/method/done.png" class="img-done" />
              </div>
              <div
                class="text-center text-green fw-500 mt-5"
                :style="`color: ${this.$store.state.labelColor} !important'`"
              >
                <span>Your passcode has been changed!</span>
              </div>
              <div
                id="otp"
                class="inputs d-flex flex-row justify-content-center mt-5"
              >
                <material-button
                  class="mb-4 w-50 bg-green"
                  @click="redirectToSignIn()"
                  :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
                  >LOGIN
                </material-button>
              </div>
            </div>
          </div>
          <div class="card-body" v-else>
            <div
                class="text-center text-green fw-500 mt-5"
                :style="`color: ${this.$store.state.labelColor} !important'`"
              >
                <span>{{ messageInvalidLink }}</span>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2" style="z-index: 10001 !important;">
      <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
                :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
                :close-handler="closeSnackbar" />
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>

<script>
import MaterialAlert from "@/components/MaterialAlert.vue";
import { mapMutations } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import VOtpInput from "vue3-otp-input";
import MaterialButton from "@/components/MaterialButton.vue";
// import { useRoute } from 'vue-router'
import { ref } from "vue";
import AuthService from "../services/auth.service";
export default {
  name: "sign-in",
  components: {
    MaterialAlert,
    MaterialSnackbar,
    VOtpInput,
    MaterialLoading,
    MaterialButton
  },
  setup() {
    const inputOTP = ref(null);

    const clearInputOTP = () => {
      inputOTP.value.clearInput();
    };
    return { inputOTP, clearInputOTP };
  },
  data() {
    return {
      message: "",
      snackbar: null,
      error: "",
      valueNewPassword: '',
      valueNewPasswordAgain: '',
      isNewPassword: true,
      isNewPasswordAgain: false,
      isUpdateSuccess: false,
      timerEnabled: true,
      merchantCode: "",
      loaderActive: false,
      isValidLink: false,
      messageInvalidLink: '',
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    const code = this.$router.currentRoute.value.params.merchant_code ?? "";
    localStorage.setItem("MERCHANT_CODE", code);
    this.merchantCode = code ? `/${code}` : "";
    // const token = TokenService.getLocalAccessToken();
    // const route = useRoute()
    this.validateLinkForgetPasscodeByCode();
  },
  computed: {
    styleObject() {
      return {
        "--label-color": this.$store.state.labelColor,
      };
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    closeSnackbar() {
      this.snackbar = null;
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    handleOnCompleteNewPassword: function (value) {
      this.valueNewPassword = value;
      this.isNewPassword = false;
      this.isNewPasswordAgain = true;
    },
    handleOnCompleteNewPasswordAgain: function (value) {
      this.closeSnackbar()
      this.showLoader()
      AuthService.updatePasscode(this.$route.query.code, value).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.isNewPassword = false;
            this.isNewPasswordAgain = false;
            this.isUpdateSuccess = true;
          } else {
            this.snackbar = 'danger'
            this.message = response.data.result.message
            this.setTimeOutNotification()
          }
          this.hideLoader()
        },
        (error) => {
          this.snackbar = 'danger'
          this.message = error
          this.hideLoader()
        }
      )
    },
    redirectToSignIn() {
      this.$router.push({ path: this.merchantCode + "/sign-in" });
    },
    validateLinkForgetPasscodeByCode() {
      this.closeSnackbar()
      this.showLoader()
      AuthService.validateLinkForgetPasscodeByCode(this.$route.query.code).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.messageInvalidLink = '';
            this.isValidLink = true;
          } else {
            this.messageInvalidLink = response.data.result.message;
            this.isValidLink = false;
          }
          this.hideLoader()
        },
        (error) => {
          this.snackbar = 'danger'
          this.message = error
          this.hideLoader()
        }
      )
    }
  },
};
</script>
<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border: none;
  border-bottom: 2px solid var(--label-color);
  text-align: center;
  border-radius: 0px !important;
  background: none;
}

.form-control:disabled,
.form-control[readonly] {
  padding: 2px !important;
}

.text-green {
  color: #ffbe30;
}

.otp-input:focus {
  outline: none !important;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>
