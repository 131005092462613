<template>
  <div class="page-header align-items-start min-vh-100">
    <span class="mask bg-white"></span>
    <div class="container">
      <div class="row" v-if="isLoadDone">
        <div class="col-lg-12 col-md-12 col-12 pt-2 custom-icon-back">
          <a @click="myTransactions()"
            ><i
              class="fas fa-chevron-left color-common"
              :style="`color: ${this.$store.state.labelColor} !important`"
            ></i
          ></a>
        </div>
        <div class="col-lg-12 col-md-8 col-12 mx-auto">
          <div class="card-body ps-0 pe-0">
            <div>
              <div
                class="color-common pb-3 font-weight-bolder h4"
                :style="`color: ${this.$store.state.labelColor} !important`"
              >
                Transaction Details
              </div>
              <div class="d-flex account-balance">
                <span class="label">Transaction Type</span>
                <span class="dots"></span>
                <span class="text-size-14">{{
                  transactionDetail.transaction_type.TYPE
                }}</span>
              </div>

              <div
                class="d-flex account-balance"
                v-if="transactionDetail.transaction_id"
              >
                <span class="label">Transaction ID</span>
                <span class="dots"></span>
                <span class="text-size-14">{{
                  transactionDetail.transaction_id
                }}</span>
              </div>

              <div class="d-flex account-balance">
                <span class="label">Status</span>
                <span class="dots"></span>
                <span
                  class="text-size-14"
                  v-bind:class="
                    transactionDetail.status == 1
                      ? 'text-success'
                      : 'text-danger'
                  "
                  >{{
                    transactionDetail.status == 1 ? "Successful" : "Failed"
                  }}</span
                >
              </div>
              <div class="d-flex account-balance">
                <span class="label">Amount</span>
                <span class="dots"></span>
                <span class="text-size-14">
                  {{ transactionDetail.transaction_type.SIGN }}
                  {{ transactionDetail.payment_amount }}</span
                >
              </div>

              <div class="d-flex account-balance">
                <span class="label">Date Time</span>
                <span class="dots"></span>
                <span class="text-size-14">
                  {{ transactionDetail.createdAt }}</span
                >
              </div>

              <div class="d-flex account-balance">
                <span class="label">Location</span>
                <span class="dots"></span>
                <span class="text-size-14">
                  {{ transactionDetail.transaction_merchant.brand_name }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MemberService from "../services/member.service";
import "bootstrap/dist/js/bootstrap.bundle";

export default {
  name: "sign-in",
  components: {
    MaterialSnackbar,
  },
  data() {
    return {
      transactionDetail: {},
      transactionType: [],
      isLoadDone: false,
      loaderActive: false,
    };
  },
  mounted() {
    this.getTransactionDetail();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),
    ...mapState(["isPinned"]),
    getTransactionDetail() {
      this.isLoadDone = false;
      this.showLoader();
      var transactionID = this.$route.params.transaction_id;
      MemberService.transactionDetail(transactionID).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.transactionDetail = response.data.data;
            this.transactionType = response.data.data.transaction_type;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
          }
          this.isLoadDone = true;
          this.hideLoader();
        },
        (error) => {
          this.isLoadDone = true;
          console.log(error);
        }
      );
    },
    myTransactions() {
      this.$router.go(-1);
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style scoped>
.transaction-detail {
  font-weight: 500;
}

.text-custom {
  color: black !important;
  font-weight: 500;
}

.font-custom {
  font-size: 14px;
}

.custom-icon-back {
  font-size: 25px;
}
.dots {
  border-bottom: 1px dotted #ccc;
  flex-grow: 1;
}
.account-balance {
  flex-grow: 1;
  align-items: baseline;
  margin-top: 15px;
}
.account-balance .label {
  font-weight: bold;
  color: #000;
  font-size: 15px;
}
.text-success {
  color: #28a745 !important;
}
</style>