import api from "./api";

class MemberPreferenceService {
    getListDropdown(memberId) {
        return api.get('/member/get-member-preference-dropdown/' + memberId);
    }
    changeMemberPreference(dataForm) {
        return api.post("/member/change-member-preference", dataForm);
    }
}

export default new MemberPreferenceService();
