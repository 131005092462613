import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/css/custom.css";
import MaterialDashboard from "./material-dashboard";
import setupInterceptors from './services/setupInterceptors';
import vue3GoogleLogin from 'vue3-google-login'
import DisableAutocomplete from "vue-disable-autocomplete";
import eventBus from "vue3-eventbus";

const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = event => {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: el => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

const appInstance = createApp(App);
appInstance.use(vue3GoogleLogin, {
  clientId: '543864626242-ujk506i6p2sdou5l5sbc7dgq9tlllj8h.apps.googleusercontent.com'
})
appInstance.directive("click-outside", clickOutside)
appInstance.use(store);
appInstance.use(router);
appInstance.use(DisableAutocomplete);
appInstance.use(MaterialDashboard);
appInstance.use(eventBus);
setupInterceptors();
appInstance.mount("#app");
