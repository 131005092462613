<template>
  <div :class="`${extraClass} form-check`">
    <input
      :id="id"
      class="form-check-input"
      type="radio"
      :name="name"
      :value="$attrs.value"
      :checked="modelValue === $attrs.value"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <label class="custom-control-label ms-2" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "MaterialRadio",
  props: {
    name: {
      type: String,
      required: true,
    },
    extraClass: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
