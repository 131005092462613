<template>
  <div
    :style="styleObject"
    class="page-header align-items-start min-vh-screen my-transacions"
  >
    <span class="mask bg-white"></span>
    <div class="container p-0">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mx-auto ps-0 pe-0">
          <div class="card-body p-0">
            <div class="color-common pb-2 font-weight-bolder px-4 pt-2">
              <h3
                class="ps-3"
                :style="`color: ${this.$store.state.labelColor} !important`"
              >
                MY CAMPAIGNS
              </h3>
            </div>
          </div>
        </div>
        <div class="p-0" v-if="isLoadDone">
          <div v-if="campaigns.length > 0" class="px-4 pb-90">
            <div
              class="campaign-item mb-2"
              v-for="(campaign, index) in campaigns"
              :key="index"
            >
              <div class="c-header" v-if="campaign.icon">
                <img
                  :src="`${campaign.image_url}`"
                  alt="error"
                  width="100%"
                  height="auto"
                />
              </div>
              <div class="c-content text-center mt-3">
                <div class="text-bold text-break">
                  {{ campaign.campaign_tile }}
                </div>
                <div class="text-break">
                  <span v-if="campaign.condition_type == spendCondition">
                    Spend
                    <strong
                      >S$
                      {{
                        new Intl.NumberFormat("en", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }).format(campaign.minimum_spend_amount)
                      }}</strong
                    >
                    to get voucher/discount code
                  </span>
                  <span v-if="campaign.condition_type == purchaseCondition">
                    Purchase
                    <strong>{{
                      campaign.minimum_number_of_item_purchased
                    }}</strong>
                    items to get voucher/discount code
                  </span>
                  <span v-if="campaign.condition_type == visitOfStoreCondition">
                    Visit our store
                    <strong>{{ campaign.number_of_stores_visit }}</strong> times
                    to get voucher/discount code
                  </span>
                </div>
                <div
                  class="c-chart"
                  v-if="campaign.condition_type != noCondition"
                >
                  <apexchart
                    height="200"
                    :options="chartOptions(campaign)"
                    :series="getDataChart(campaign)"
                  ></apexchart>
                </div>
                <div class="mt-2 d-flex justify-content-center">
                  <button
                    v-if="
                      campaign.reward_type == 2 &&
                      campaign.received &&
                      !campaign.received.success
                    "
                    class="mb-1 d-flex justify-content-center align-items-center px-2"
                  >
                    {{ campaign.received.message }}
                  </button>
                  <button
                    v-else-if="checkProgress(campaign)"
                    class="mb-1 no-bg d-flex justify-content-center align-items-center btn-sm"
                    :style="`border: 1px solid ${this.$store.state.labelColor} !important; color:#222222`"
                    @click="getReward(campaign)"
                  >
                    <span
                      v-if="
                        checkProgress(campaign) &&
                        campaign.reward_type == 1 &&
                        campaign.received
                      "
                    >
                      VIEW VOUCHER
                    </span>
                    <span
                      v-else-if="
                        checkProgress(campaign) && campaign.reward_type == 2
                      "
                    >
                      VIEW DISCOUNT CODE
                    </span>
                    <span
                      v-else
                    >
                      GET
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              class="d-flex justify-content-center p-5"
              style="background-color: #f0f2f5"
            >
              No campaigns available
            </div>
          </div>
        </div>
      </div>
    </div>
    <material-loading :active="loaderActive" />
    <div class="position-fixed top-1 end-1 z-index-2">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <ModalCampaignReward
      :voucherDetail="voucherDetail"
      :processExchange="processExchange"
    />
    <ModalSuccess
      :processAcction="gotoMyReward"
      message="Voucher has been exchanged successfully and saved to My Reward"
      buttonText="Go to My Reward"
    />
    <QrcodeReward :rewardDetail="voucherDetailReward" :maxHeightModal="true"/>
    <QrcodeRewardDiscount :discountDetail="discountDetail"/>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import VueApexCharts from "vue3-apexcharts";
import CampaignService from "../services/campaign.service";
import RewardService from "../services/reward.service";
import MaterialLoading from "@/components/MaterialLoading.vue";
import ModalCampaignReward from "@/components/ModalCampaignReward.vue";
import ModalSuccess from "@/components/ModalSuccess.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import QrcodeReward from "@/components/QrcodeReward.vue";
import QrcodeRewardDiscount from "@/components/QrcodeRewardDiscount.vue";
import $ from "jquery";

export default {
  name: "my-campaigns",
  components: {
    apexchart: VueApexCharts,
    MaterialLoading,
    ModalCampaignReward,
    ModalSuccess,
    MaterialSnackbar,
    QrcodeReward,
    QrcodeRewardDiscount
  },
  data() {
    return {
      campaigns: [],
      loaderActive: false,
      snackbar: null,
      message: "",
      isLoadDone: false,
      merchantCode: "",
      spendCondition: 1,
      purchaseCondition: 2,
      visitOfStoreCondition: 3,
      noCondition: 4,
      discountDetail: {},
      voucherDetail: {},
      voucherDetailReward: {},
      campaignIdSelect: 0,
    };
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
    this.getCampaigns();
  },
  methods: {
    ...mapMutations([
      "toggleEveryDisplay",
      "toggleHideConfig",
      "navbarMinimize",
    ]),
    ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),
    ...mapState(["isPinned"]),
    chartOptions(campaign) {
      var total = 100;

      if (campaign.condition_type == this.spendCondition) {
        total = campaign.minimum_spend_amount;
      }

      if (campaign.condition_type == this.purchaseCondition) {
        total = campaign.minimum_number_of_item_purchased;
      }

      if (campaign.condition_type == this.visitOfStoreCondition) {
        total = campaign.number_of_stores_visit;
      }

      return {
        chart: {
          type: "radialBar",
        },
        colors: ["#fc3a01"],
        plotOptions: {
          radialBar: {
            startAngle: -120,
            endAngle: 120,
            background: "red",
            hollow: {
              margin: 15,
              size: "70%",
            },
            dataLabels: {
              show: true,
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: "20px",
                fontWeight: 700,
                color: "#fc3a01",
                offsetY: 0,
                formatter: function () {
                  return parseInt(campaign.total ?? 0) + "/" + total;
                },
              },
            },
            track: {
              background: "#fcd8c5",
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
      };
    },
    checkProgress(campaign) {
      if (campaign.condition_type == this.noCondition) {
        return true;
      }

      if (
        campaign.condition_type == this.spendCondition &&
        campaign.total >= campaign.minimum_spend_amount
      ) {
        return true;
      }

      if (
        campaign.condition_type == this.purchaseCondition &&
        campaign.total >= campaign.minimum_number_of_item_purchased
      ) {
        return true;
      }

      if (
        campaign.condition_type == this.visitOfStoreCondition &&
        campaign.total >= campaign.number_of_stores_visit
      ) {
        return true;
      }

      return false;
    },
    getDataChart(campaign) {
      var chartData = [];
      const maxChartData = 100;
      if (campaign.condition_type == this.spendCondition) {
        if (campaign.total >= campaign.minimum_spend_amount) {
          chartData.push(maxChartData);
        } else {
          chartData.push(
            campaign.total / (campaign.minimum_spend_amount / maxChartData)
          );
        }
      }

      if (campaign.condition_type == this.purchaseCondition) {
        if (campaign.total >= campaign.minimum_number_of_item_purchased) {
          chartData.push(maxChartData);
        } else {
          chartData.push(
            campaign.total /
              (campaign.minimum_number_of_item_purchased / maxChartData)
          );
        }
      }

      if (campaign.condition_type == this.visitOfStoreCondition) {
        if (campaign.total >= campaign.number_of_stores_visit) {
          chartData.push(maxChartData);
        } else {
          chartData.push(
            campaign.total / (campaign.number_of_stores_visit / maxChartData)
          );
        }
      }
      return chartData;
    },
    getCampaigns() {
      this.showLoader();
      this.isLoadDone = false;
      CampaignService.getList([]).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.campaigns = response.data.data.campaigns;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.isLoadDone = true;
          this.hideLoader();
        },
        (error) => {
          this.isLoadDone = true;
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },
    async getReward(campaign) {
      this.showLoader();
      this.campaignIdSelect = campaign.id;
      if (campaign.reward_type == 1) {
        await RewardService.getRewardDetail(campaign.voucher_id, 0, campaign.received ? campaign.id : 0).then(
          (response) => {
            if (response.data.result.isSuccess) {
              if (campaign.received) {
                this.voucherDetailReward = response.data.data.reward;
                $(".btn-qrcode-modal").click();
              } else {
                this.voucherDetail = response.data.data.reward;
                $(".btn-campaign-modal").click();
              }
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
            }
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
          }
        );
      } else {
        await CampaignService.discountDetail({
          reward_id: campaign.discount_id,
          campaign_id: campaign.id
        }).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.discountDetail = response.data.data.discount;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
            }
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
          }
        );
        $(".btn-qrcode-discount-modal").click()
      }
      this.hideLoader();
    },
    processExchange(rewardId) {
      this.closeSnackbar();
      this.showLoader();
      CampaignService.processExchange({
        reward_id: rewardId,
        campaign_id: this.campaignIdSelect,
      }).then(
        (response) => {
          if (response.data.result.isSuccess) {
            $(".btn-close").click();
            $(".btn-success-modal").click();
            this.getCampaigns();
          } else {
            $(".btn-close").click();
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },
    gotoMyReward() {
      $(".btn-close").click();
      this.$router.push(this.merchantCode + "/my-rewards");
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    closeSnackbar() {
      this.snackbar = null;
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
  computed: {
    styleObject() {
      return {
        "--label-color": this.$store.state.labelColor,
      };
    },
  },
};
</script>
<style>
.campaign-item {
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
  color: #000000;
}

.campaign-item .c-header img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.campaign-item .c-chart {
  min-height: 149px !important;
}

.campaign-item .c-content div:first-child {
  font-size: 16px;
}

.campaign-item .status-used {
  min-width: 80px;
  max-width: 150px;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  color: rgb(248, 37, 37);
  font-weight: 700;
  border: 1px solid rgb(248, 37, 37);
  font-size: 16px;
}
</style>
