<template>
  <div class="d-flex btn-sort-filter pe-0">
      <ul class="mx-auto">
        <li
          data-bs-toggle="modal"
          data-bs-target="#bottom_modal_filter"
          class="d-flex d-flex align-items-center justify-content-center py-1"
        >
          <i class="material-icons-round opacity-10 fs-5 pe-2">tune</i>
          <span>Filter</span>
          <i
            v-if="filterArrCashbackEarnStatus.length || filterArrStore.length || filterCashbackTimeSelected"
            class="fa fa-check-circle ps-2"
            style="color: #3aac5d"
          ></i>
        </li>
      </ul>
    </div>
</template>

<script>
export default {
  name: "filter-toobar",
  props: {
    id: {
      type: String,
      required: true,
    },
    filterArrCashbackEarnStatus: {
      type: Array,
    },
    filterArrStore: {
      type: Array,
    },
    filterCashbackTimeSelected: {
      type: Number,
    }
  },
};
</script>
<style scoped>
.btn-sort-filter {
  position: fixed;
  bottom: 30px;
  width: 92%;
}
.btn-sort-filter ul {
  margin: 0px;
  padding: 0px;
  width: 205x;
  border: 1px solid #d6d6d6;
  border-radius: 20px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  background: #ffffff;
}
.btn-sort-filter ul li {
  list-style: none;
  float: left;
  width: 150px;
}
.btn-sort-filter ul li span {
  color: #222222;
}
.btn-sort-filter ul li:hover {
  cursor: pointer;
}
</style>
