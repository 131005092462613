<template>
  <div
    class="modal modal-bottom fade"
    id="campaign-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="campaign-modal"
    style="z-index: 10000"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" style="border: 0; padding: 0.1rem 1rem">
          <!-- <h5 class="modal-title">Bottom Modal Title</h5> -->
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-8 col-12 mx-auto">
              <div class="mb-3" style="position: relative">
                <div class="row">
                  <div class="voucher-item">
                    <div class="d-flex justify-content-between">
                      <div class="col-4 ps-1">
                        <img
                          :src="`${voucherDetail.icon_url}`"
                          class="voucher-image"
                          alt="user1"
                        />
                      </div>
                      <div class="col-8 ps-0">
                        <div class="d-flex align-items-center">
                          <strong
                            class="text-size-13 ps-0"
                            :style="`color: ${this.$store.state.labelColor} !important`"
                            >{{ voucherDetail.reward_title ?? "" }}</strong
                          >
                        </div>
                        <div class="d-flex">
                          <em class="text-size-13"
                            >Valid for: {{ voucherDetail.expiry_date_text }}</em
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2" v-if="voucherDetail.tier_can_exchange_text">
                <div class="d-flex bd-highlight">
                  <i class="fas fa-check pe-2 pt-1" style="color: #049e0e"></i>
                  <p class="font-content text-dark">
                    {{ voucherDetail.tier_can_exchange_text }}
                  </p>
                </div>
              </div>
              <div
                class="row mb-2"
                v-if="voucherDetail.branch_can_exchange_text"
              >
                <div class="d-flex bd-highlight">
                  <i class="fas fa-check pe-2 pt-1" style="color: #049e0e"></i>
                  <p class="font-content text-dark">
                    {{ voucherDetail.branch_can_exchange_text }}
                  </p>
                </div>
              </div>
              <div class="row mb-2" v-if="voucherDetail.item_apply_text">
                <div class="d-flex bd-highlight">
                  <i class="fas fa-check pe-2 pt-1" style="color: #049e0e"></i>
                  <p class="font-content text-dark">
                    {{ voucherDetail.item_apply_text }}
                  </p>
                </div>
              </div>
              <div class="row mb-2" v-if="voucherDetail.order_minimum_text">
                <div class="d-flex bd-highlight">
                  <i class="fas fa-check pe-2 pt-1" style="color: #049e0e"></i>
                  <p class="font-content text-dark">
                    {{ voucherDetail.order_minimum_text }}
                  </p>
                </div>
              </div>
              <div class="text-center">
                <material-button
                  class="btn mb-3 col-lg-2 col-md-5 col-4 bg-color-common mt-5"
                  :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
                  @click="processExchange(voucherDetail.id)"
                >
                  GET
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="btn-campaign-modal"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#campaign-modal"
    >
      Success
    </button>
  </div>
</template>

<script>
export default {
  name: "CampaignReward",
  components: {},
  props: {
    voucherDetail: {
      type: Object,
      default() {
        return {};
      },
    },
    processExchange: {
      type: Function,
      default: () => {},
    },
  },
  methods: {},
};
</script>
<style scoped>
.bg-color-common {
  color: #fff;
}

#campaign-modal .modal-dialog {
  height: 50vh;
}

#campaign-modal .modal-body {
  overflow-y: scroll;
  height: 48vh;
}

.wrap-qrcode {
  width: 200px;
  margin: 0px auto;
}

#campaign-modal p {
  margin: 0px;
}

/* Css model */
.modal.modal-left .modal-dialog,
.modal.modal-right .modal-dialog,
.modal.modal-top .modal-dialog,
.modal.modal-bottom .modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/* Left & Right */

.modal.modal-left .modal-dialog,
.modal.modal-right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 500px;
  max-width: 100%;
  height: 100%;
}

.modal.modal-left .modal-content,
.modal.modal-right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.modal-left .modal-body,
.modal.modal-right .modal-body {
  padding: 15px 15px 80px;
}

.modal.modal-left.fade .modal-dialog {
  left: -500px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.modal-left.fade.show .modal-dialog {
  left: 0;
}

.modal.modal-right.fade .modal-dialog {
  right: -500px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.modal-right.fade.show .modal-dialog {
  right: 0;
}

.modal.modal-left .modal-content,
.modal.modal-right .modal-content,
.modal.modal-top .modal-content,
.modal.modal-bottom .modal-content,
.modal.modal-full .modal-content {
  border-radius: 0;
  border: none;
}

.modal.modal-left .modal-dialog.modal-sm,
.modal.modal-right .modal-dialog.modal-sm {
  width: 300px;
}

.modal.modal-left .modal-dialog.modal-lg,
.modal.modal-right .modal-dialog.modal-lg {
  width: 800px;
}

.modal.modal-left .modal-dialog.modal-xl,
.modal.modal-right .modal-dialog.modal-xl {
  width: 1140px;
}

/* Top and Bottom */

.modal.modal-top .modal-dialog,
.modal.modal-bottom .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.modal.modal-top .modal-content,
.modal.modal-bottom .modal-content {
  height: auto;
  overflow-y: auto;
}

.modal.modal-top .modal-body,
.modal.modal-bottom .modal-body {
  padding: 15px 15px;
}

/* Top */

.modal.modal-top.fade .modal-dialog {
  top: -100%;
  -webkit-transition: opacity 0.3s linear, top 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, top 0.3s ease-out;
  -o-transition: opacity 0.3s linear, top 0.3s ease-out;
  transition: opacity 0.3s linear, top 0.3s ease-out;
}

.modal.modal-top.fade.show .modal-dialog {
  top: 0;
}

/* Bottom */

.modal.modal-bottom.fade .modal-dialog {
  bottom: -100%;
  -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  transition: opacity 0.3s linear, bottom 0.3s ease-out;
}

.modal.modal-bottom.fade.show .modal-dialog {
  bottom: 0;
}

.modal.modal-bottom.fade .modal-dialog {
  bottom: -100%;
}

/* Full Screen */

.modal.modal-full .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.modal.modal-full .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.modal-full .close-modal {
  position: fixed;
  top: 0;
  right: 3rem;
}

/* Footer */

.modal-footer-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-radius: 0;
}

/* XS */

.modal.modal-left.xs .modal-body,
.modal.modal-right.xs .modal-body {
  padding: 15px;
}

.btn-close {
  font-size: 25px;
  color: #222 !important;
}

.btn-close span {
  color: #222;
}

.wrap-code {
  border: 1px solid rgb(228, 228, 228);
  position: relative;
  width: 200px;
  margin: 0px auto;
  left: 0px;
}

.wrap-code i {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>
