class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("member"));
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("member"));
    return user?.data.authorisation.token;
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("member"));
    user.authorisation.token = token;
    localStorage.setItem("member", JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem("member"));
  }

  setUser(user) {
    localStorage.setItem("member", JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem("member");
    localStorage.removeItem("MEMBER_PASSCODE");
  }

  setMember(member) {
    localStorage.setItem("member", JSON.stringify(member));
  }

  getMember() {
    return JSON.parse(localStorage.getItem("member"));
  }
}

export default new TokenService();
